import { SkyscraperLayout } from '@wh/common/chapter/components/Layouts/SkyscraperLayout'
import { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { NextPage } from 'next'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import React, { Fragment } from 'react'
import { DesktopBreadcrumbs } from '@bbx/search-journey/sub-domains/search/components/common/result-list/DesktopBreadcrumbs/DesktopBreadcrumbs'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { DealerProfile } from '@bbx/ad-in/organisation/types/DealerProfile'
import { ExpressNextContextWithUserData } from '@wh/common/chapter/types/nextJS'
import { concatPathWithQueryParams, getFirstString } from '@wh/common/chapter/lib/urlHelpers'
import { getAlertSearchResult, getSeoSearchResult } from '@bbx/search-journey/common/api/searchApiClient'
import { getDealerProfile } from '@bbx/ad-in/organisation/api/dealerProfileApiClient'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'
import { DealerProfilePageContent } from '@bbx/ad-in/organisation/components/DealerProfilePageContent'
import { AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'

export type DealerProfileProps =
    | {
          dealerAds: AdvertSummaryList
          searchResult: SearchResult
          dealerProfile: DealerProfile
      }
    | {
          is404: true
      }

const DealerProfilePage: NextPage<DealerProfileProps> & { Layout: typeof Layout } = (props) => {
    if ('is404' in props) {
        return <ErrorPage statusCode={404} />
    } else {
        return (
            <Fragment>
                <DesktopBreadcrumbs breadcrumbs={dealerProfileAutoBreadcrumbs} />
                <DealerProfilePageContent
                    dealerAds={props.dealerAds}
                    searchResult={props.searchResult}
                    dealerProfile={props.dealerProfile}
                />
            </Fragment>
        )
    }
}

export const dealerProfileGetInitialProps =
    (basePath: string) =>
    async ({ query, req, res }: ExpressNextContextWithUserData): Promise<DealerProfileProps> => {
        const { orgId: rawOrgId, alertId } = query
        const dealerProfileOrgId = getFirstString(rawOrgId)
        const searchAgentId = getFirstString(alertId)

        if (!dealerProfileOrgId) {
            if (res) {
                res.statusCode = 404
            }
            return { is404: true }
        }

        try {
            const dealerAdsQuery = { orgId: dealerProfileOrgId, rows: '30', page: '1' }
            const concatenatedPath = concatPathWithQueryParams(`/gebrauchtwagen${basePath}`, { ...query })
            const dealerAdsPath = concatPathWithQueryParams(`/gebrauchtwagen${basePath}`, { ...dealerAdsQuery })
            const dealerAdsPromise = getSeoSearchResult(dealerAdsPath, req)
            const searchResultPromise = searchAgentId ? getAlertSearchResult(query, req) : getSeoSearchResult(concatenatedPath, req)
            const dealerProfilePromise = getDealerProfile(dealerProfileOrgId, req)

            // when quering the initial search, all the ads of an org are fetched.
            // the searchResult changes later when the filters are changed
            // the dealerAds stay true to the same initial search
            const [searchResult, dealerProfile, dealerAds] = await Promise.all([
                searchResultPromise,
                dealerProfilePromise,
                dealerAdsPromise,
            ])

            return { dealerAds: dealerAds.advertSummaryList, searchResult, dealerProfile }
        } catch (error) {
            const apiError = wrapInApiErrorIfNecessary(error)

            if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
                if (res) {
                    res.statusCode = 404
                }
                return { is404: true }
            }

            throw apiError
        }
    }

DealerProfilePage.getInitialProps = dealerProfileGetInitialProps('/gebrauchtwagenhaendler-detail-auto')
DealerProfilePage.Layout = SkyscraperLayout

export default DealerProfilePage

export const dealerProfileAutoBreadcrumbs = [
    { displayName: 'Startseite', seoUrl: staticRelativeCanonicals.Home },
    { displayName: 'Auto & Motor', seoUrl: staticRelativeCanonicals.MotorHome },
    { displayName: 'Händlerprofil', seoUrl: '' },
]
