import React from 'react'
import { HorizontalSlider } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { CdcAdvertSummaryDiscriminator } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryDiscriminator'
import { AdvertSummary, AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'
import { CdcAd } from '@bbx/common/types/UserFeed'
import { convertToVerticalId } from '@wh/common/chapter/types/verticals'
import { Box } from '@wh-components/core/Box/Box'

// 2 pages with 5 each. The horizontal slider does not have paging, so a click on "next" page always goes to the last one, making 2 pages the maximum
const NUMBER_OF_ADS_LIMIT = 10

export const HighlightedAds = (props: { ads: AdvertSummaryList }) => {
    return (
        <Box marginRight={{ phone: -'m' }}>
            <HorizontalSlider
                heading=""
                testIdPrefix="highlighted-ads-slider"
                onClick={undefined}
                onClickButton={undefined}
                onClose={undefined}
                numberOfAdsLimit={NUMBER_OF_ADS_LIMIT}
                visibilityTrackingRef={undefined}
                variant="portrait"
                autoScrollInterval={30000}
            >
                {props.ads.advertSummary.slice(0, NUMBER_OF_ADS_LIMIT).map((advertSummary) => {
                    const ad = convertToCdcAd(advertSummary)
                    return (
                        <CdcAdvertSummaryDiscriminator
                            ad={ad}
                            key={`highlighted-${ad.id}`}
                            testIdPrefix="highlighted-ads"
                            variant="landscape"
                            imgHeight={170}
                        />
                    )
                })}
            </HorizontalSlider>
        </Box>
    )
}

const convertToCdcAd = (advertSummary: AdvertSummary): CdcAd => {
    return {
        id: parseInt(advertSummary.id, 10),
        verticalId: convertToVerticalId(advertSummary.verticalId),
        adTypeId: advertSummary.adTypeId,
        productId: advertSummary.productId,
        advertStatus: {
            description: advertSummary.advertStatus?.description ?? '',
            id: advertSummary.advertStatus?.id ?? '',
        },
        description: advertSummary.description,
        attributes: advertSummary.attributes.attribute,
        teaserAttributes: null,
        advertImageList: advertSummary.advertImageList.advertImage,
        contextLinkList: advertSummary.contextLinkList.contextLink,
        publishDate: '',
        postalName: undefined,
    }
}
